import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import { Context } from "../../..";
import { LOCALES } from "../../../core/i18n/locales";

import { FormattedMessage } from "react-intl"

import InputMask from 'react-input-mask'

import { StorageKeys } from "../../../core/utils/defaults";
import { prevent } from "../../../core/utils/utils";
import useWindowDimensions from "../../../core/hooks/useWindowDimensions";
import { message } from "antd";
import BlurModal from "../../../components/modals/BlurModal";
import { LoadingOutlined } from "@ant-design/icons";
import { ApiService } from "../../../core/api/api";
import SupportModal from "../Modals/SupportModal";
import RulesModal from "../Modals/RulesModal";
import ExcludedModal from "../Modals/ExcludedModal";

import styles3 from "../../../styles/Loyalty/LoyaltyOrders.module.scss"
import styles2 from "../../../styles/Loyalty/LoyaltyStore.module.scss"
import styles from "../../../styles/Footer.module.scss"

const LoyaltyFooter: FC = () => {

  const { store, loyaltyStore } = useContext(Context);
  const navigate = useNavigate()
  const location = useLocation()

  const { width } = useWindowDimensions()

  const [promocode, setPromocode] = useState("")
  const [pcLoading, setPCLoading] = useState(false)

  const navTo = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    let url = event.target.pathname || "/";

    if (event.target.tagName.toLowerCase() == "span") {
      url = event.target.parentElement.pathname;
    }

    if (url != location.pathname) {
      console.log(event, url);
  
      store.setIsFaderVisible(true);
      setTimeout(() => {
        store.setIsFaderShown(true);
        setTimeout(() => {
          navigate(url);
        }, 400);
      }, 100);
    }
  }

  const activateCode = async () => {
    setPCLoading(prev => prev = true)

    const res = await ApiService.loyalty.promos.activatePromoCode({
      code: promocode.replaceAll("-", ""),
      user_id: store.userData.id
    })

    console.log("activatePromoCode()", res.data)

    setPCLoading(prev => prev = false)

    if (res.data.state == 0) {
      message.error(res.data.message)
      return
    }

    setPromocode(prev => prev = "")

    message.success("Промокод успешно применен!")

    setTimeout(() => {
      window.location.reload()
    }, 2000)
  }

  const setupLocale = (locale: string) => {
    store.setLocale(locale)
    localStorage.setItem(StorageKeys.LOCALE, locale)
  }

  useEffect(() => {
    
  }, [])
  
  return (
    <>
      <div className={styles.section_footer} id="footer" style={{opacity: store.isAuth ? "1" : "1"}}>
        <div className="container">
          <div className="row">
            
            <div className="col-12">
              <div className={styles.copys}>
                <div className="row">
                  <div className="col-12 col-md-5">
                    <div className={styles.copys_links}>
                      {width > 768 ? <></> : <>
                        <a href="/rules" onClick={(ev) => {
                          prevent(ev)
                          loyaltyStore.setIsRulesVisible(true)
                        }}>Правила бонусной программы</a>
                      </>}
                      <a target="_blank" href="/support" onClick={(ev) => {
                        prevent(ev)
                        loyaltyStore.setIsSupportVisible(true)
                      }}>{store.locale == LOCALES.RUSSIAN ? "Поддержка" : "Support"}</a>
                      <a target="_blank" href="https://topgun.plus/uploads/Пользовательское_соглашение_TOPGUN_Plus.docx">{store.locale == LOCALES.RUSSIAN ? "Пользовательское соглашение" : "Terms of use"}</a>

                      {store.isAuth ? <a target="_blank" href="/promocode" onClick={(ev) => {
                        prevent(ev)
                        loyaltyStore.setIsPromoVisible(true)
                      }}>{store.locale == LOCALES.RUSSIAN ? "Активировать промокод" : "Activate Promo-code"}</a> : <></>}
                    </div>
                  </div>
                  <div className="col-12 col-md-7">
                    <div className={styles.copys_links_r}>
                      <div className={styles.copys_links_r_w}>
                        <div className={styles.txt}><FormattedMessage id="footer_copys_loyalty" /></div>
                        <div className={styles.txt}>© TOPGUN  2014 — 2024</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
        
      <BlurModal
        visible={loyaltyStore.isPromoVisible}
        title=""
        onClose={() => loyaltyStore.setIsPromoVisible(false)}
        centered={true}
        content={<>
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-12">
                <div className={styles2.blurmodal_centered}>
                  <h3><b>Активировать <span>промокод</span></b></h3>
                
                  <div className="row">
                    <div className="col-12 col-md-6 offset-md-3">
                      <div className={styles2.xcard}>
                        
                        <div className={styles3.form} id="form1" style={{opacity: 1}}>
                          <div className={styles3.form_field}>
                            <label className={styles3.form_field__label} htmlFor="name">Введите промокод</label>
                            <div className={styles3.form_field__input}>
                              {/* <input type="text" placeholder="****-****-****" /> */}
                              <InputMask mask="****-****-****" maskChar="_" disabled={pcLoading} readOnly={pcLoading} style={{textTransform: "uppercase"}} placeholder="____-____-____" value={promocode} onChange={(event) => {
                                // console.log(event)
                                console.log(event.target.value)
                                setPromocode(prev => prev = event.target.value)
                              }} />
                            </div>
                          </div>

                          <div className={styles3.form_actions} style={{
                            width: "100%", textAlign: "center", marginTop: "0px"
                          }}>
                            <button className={styles3.darked} onClick={() => pcLoading ? false : activateCode()}>{pcLoading ? <LoadingOutlined spin rev={null} /> : "Активировать"}</button>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                
                </div>
              </div>
            </div>
          </div>
        </>} />

      <RulesModal />
      <ExcludedModal />
      <SupportModal />
    </>
  )
}

export default observer(LoyaltyFooter)