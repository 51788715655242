import { makeAutoObservable } from "mobx";
import { LOCALES } from "../i18n/locales";
import {BlogPost} from "../models/BlogPost";
import {BranchM, BranchModel, Region} from "../models/Branch";
import {Translation} from "../models/Translate";
import {HomeMenuPage, StorageKeys} from "../utils/defaults";
import {isNullOrUndefined} from "../utils/utils";

export default class Store {

  isAuth = false
  token: string = ""
  userData: any = {is_barber: false}
  subscribeData: any = null
  subscribeBotData: any = null
  maxmaUserData: any = {}
  locale: string = ""
  smsCode: string = ""

  isActiveA: boolean = false;
  portfolioRendered: boolean = false;

  isBackCallVisible: boolean = false
  isMobile: boolean = false
  isMenuOpenned: boolean = false
  isFaderVisible: boolean = false
  isHomeHeadCircVis: boolean = false
  isFaderShown: boolean = false
  isFaderLogoVisible: boolean = false
  isContentShown: boolean = false
  isHomeBlockVisible: boolean = false

  isHeaderLocked: boolean = false
  selectedSlide: number = 0

  menuSelected: number = 0

  refuseDrop: boolean = false

  c1Count: string = "0";
  c2Count: string = "0";
  c3Count: string = "0";
  c4Count: string = "0";

  allOpenSum: string = "0 000 000";
  allOpenInter: any = null as any;

  animSum1: string = "0";
  animSum2: string = "0";
  animSum3: string = "0";
  animSum4: string = "0";
  animSum5: string = "0";
  animSum6: string = "0";
  animSum7: string = "0";
  animHiderLine: number = 0;

  animSum8: string = "0";
  animSum9: string = "0.0";
  animSum10: string = "0";
  animSum11: string = "0";

  animSum12: string = "0 000 000 000";
  animSum121: string = "0.0";
  animSum13: string = "000 000";
  animSum131: string = "000";
  animSum14: string = "000";
  animSum15: string = "0";

  animT1: number = 0;
  animT1Left: number = 0;

  animationToggle: boolean = false;
  cInit: boolean = false

  branchesStats: any | null = null
  branches: BranchModel[] = [];
  allRegions: Region[] = [];
  allBranches: BranchM[] = [];
  branchesAll: BranchModel[] = [];
  posts: BlogPost[] = [];
  postsAll: BlogPost[] = [];
  postsSlides: BlogPost[] = [];

  branchesServs: any[] = []
  selectedBranchServ: any = null
  selectedBranchServSec: any = null

  gridShown: boolean = false;
  gridVisible: boolean = false;

  opennedItem: number = 0;
  selectedCountry: number = 0;
  selectedRegion: number = 0;
  selectedBranch: number = 0;

  selectedItem: any = null;
  selectedRegItem: any = null;
  selectedBranchItem: any = null;

  uploadedImage: string = "";
  uploadedImage2: string = "";
  uploadedImages: string[] = [];

  selectedPostId: number = 0;

  postCardWidth: string = "0px";

  techworks: {state: boolean} = {state: false};
  translations: any = {};
  transAll: any[] = [];
  locales: any[] = [];
  franchise: {key: string, value: string}[] = [];

  isSettingsInited: boolean = false;

  isLottieAnim: boolean = false;
  isLottieAnimPrg: boolean = false;
  isFramesLoaded: boolean = false;

  backCallFixed: boolean = false;

  xScr: number = 0;

  frames: any[] = []

  pathPageIndex: number = 0
  pathPostIndex: number = 0

  branchesMapIds: any[] = []

  lastRoute: string = "/"
  prevLastRoute: string = "/"

  swiperIndex: number = 0
  swiperIndexA: number = 0
  headSliderIndex: number = 0
  headSliderInit: boolean = false

  homeSlides: any[] = []

  platform: string = ""

  homeEvents: any[] = []
  homeCollabs: any[] = []
  franEvents: any[] = []
  franCollabs: any[] = []
  vacancySlider: any[] = []
  vacancyList: any[] = []

  isLogoutVisible: boolean = false
  isLockScreen: boolean = false

  dpAccShown: boolean = false
  dpDpShown: boolean = false

  sidebarToggled: boolean = false
  sidebarTogglex: boolean = false

  draggingTicket: any | null = null
  draggingTable: any | null = null

  whiteTheme: boolean = true

  maxmaConfig: any | null = null

  totalSale: number = 30
  bonusesList: any[] = []

  referals: any[] = []
  referalsStats: null | any = null

  ordersList: any[] = []
  ordersListF: any[] = []

  inter: null | any = null
  interTimes: number = 0
  interState: number = 0
  interStateX: number = 0

  initialY: number = 0
  initialX: number = 0

  searchText: string = ""

  isAddProdVisible: boolean = false
  isSaveProdVisible: boolean = false
  isDelProdVisible: boolean = false

  mainVisible: boolean = true
  isNotifyVisible: boolean = false

  searchModalText: string = ""

  counterRun: boolean = false

  carXVisible: boolean = false

  homeAnimEnd: boolean = true

  hidingProceed: boolean = false
  hidingProceed2: boolean = false

  subFixed: boolean = false

  clickStory: boolean = false

  isOrderWidgetVisible: boolean = false

  locationCoords: any[] = []

  isLocatorVisible: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setIsLocatorVisible(val: boolean) {
    this.isLocatorVisible = val
  }

  setLocation(val: any[]) {
    this.locationCoords = val
  }

  setIsOrderWidgetVisible(val: boolean) {
    this.isOrderWidgetVisible = val
  }
  
  setClickStore(val: boolean) {
    this.clickStory = val
  }

  setSubFixed(val: boolean) {
    this.subFixed = val
  }

  setHidingProceed(val: boolean) {
    this.hidingProceed = val
  }

  setHidingProceed2(val: boolean) {
    this.hidingProceed2 = val
  }

  setInitialY(val: number) {
    this.initialY = val
  }

  setInitialX(val: number) {
    this.initialX = val
  }

  isBotActive() {
    return this.isAuth && (this.userData.telegram_id != null && this.userData.telegram_id != null)
  }

  setHomeAnimEnd(val: boolean) {
    this.homeAnimEnd = val
  }

  setCarXVisible(val: boolean) {
    this.carXVisible = val
  }

  setCounterRun(val: boolean) {
    this.counterRun = val
  }

  setSearchModalText(val: string) {
    this.searchModalText = val
  }

  setIsNotifyVisible(val: boolean) {
    this.isNotifyVisible = val
  }

  setMainVisible(val: boolean) {
    this.mainVisible = val
  }

  setIsAddProdVisible(val: boolean) {
    this.isAddProdVisible = val
  }

  setIsSaveProdVisible(val: boolean) {
    this.isSaveProdVisible = val
  }

  setIsDeleteProdVisible(val: boolean) {
    this.isDelProdVisible = val
  }

  setSearchText(val: string) {
    this.searchText = val
  }

  setInterTimes(val: number) {
    this.interTimes = val
  }

  setInterState(val: number) {
    this.interState = val
  }

  setInterStateX(val: number) {
    this.interStateX = val
  }
  
  setInter(val: any) {
    this.inter = val
  }

  setReferals(val: any[]) {
    this.referals = val
  }

  setReferalsStats(val: any | null) {
    this.referalsStats = val
  }

  setBonusesList(val: any[]) {
    this.bonusesList = val
  }

  setOrdersList(val: any[]) {
    this.ordersList = val
  }

  setOrdersListF(val: any[]) {
    this.ordersListF = val
  }

  setTotalSale(val: number) {
    this.totalSale = val
    localStorage.setItem(StorageKeys.TOTAL_SALE, `${val}`)
  }

  setMaxmaConfig(val: any) {
    this.maxmaConfig = val
  }

  setSmsCode(val: string) {
    this.smsCode = val
  }

  setWhiteTheme(val: boolean) {
    this.whiteTheme = val
  }

  setDraggingTable(val: any | null) {
    this.draggingTable = val
  }

  setDraggingTicket(val: any | null) {
    this.draggingTicket = val
  }

  setSidebarToggled(val: boolean) {
    this.sidebarToggled = val
  }

  setSidebarTogglex(val: boolean) {
    this.sidebarTogglex = val
  }

  setDpAccShown(val: boolean) {
    this.dpAccShown = val
  }

  setDpDpShown(val: boolean) {
    this.dpDpShown = val
  }

  setLockScreen(val: boolean) {
    this.isLockScreen = val
  }

  setIsLogoutVisible(val: boolean) {
    this.isLogoutVisible = val
  }

  setHomeEvents(val: any[]) {
    this.homeEvents = val
  }

  setHomeCollabs(val: any[]) {
    this.homeCollabs = val
  }

  setFranEvents(val: any[]) {
    this.franEvents = val
  }

  setFranCollabs(val: any[]) {
    this.franCollabs = val
  }

  setVacancySlider(val: any[]) {
    this.vacancySlider = val
  }

  setVacancyList(lst: any[]) {
    this.vacancyList = lst
  }

  setPlatform(val: string) {
    this.platform = val
  }

  setSwiperIndex(index: number) {
    this.swiperIndex = index
  }

  setSwiperIndexA(index: number) {
    this.swiperIndexA = index
  }

  setHeadSliderInit(index: boolean) {
    this.headSliderInit = index
  }

  setHeadSliderIndex(index: number) {
    this.headSliderIndex = index
  }

  setAnimSum12(val: string) {this.animSum12 = val}
  setAnimSum121(val: string) {this.animSum121 = val}
  setAnimSum13(val: string) {this.animSum13 = val}
  setAnimSum131(val: string) {this.animSum131 = val}
  setAnimSum14(val: string) {this.animSum14 = val}
  setAnimSum15(val: string) {this.animSum15 = val}

  setLastRoute(val: string) {
    this.lastRoute = val
  }
  
  setPrevLastRoute(val: string) {
    this.prevLastRoute = val
  }

  setBranchesMapIds(val: any[]) {
    this.branchesMapIds = val
  }

  setPathPageIndex(val: number) {
    this.pathPageIndex = val
  }

  setPathPostIndex(val: number) {
    this.pathPostIndex = val
  }

  setFrames(val: any[]) {
    this.frames = val
  }

  setBackCallFixed(xScr: boolean) {
    this.backCallFixed = xScr
  }

  setXScr(xScr: number) {
    this.xScr = xScr
  }

  setIsFramesLoaded(value: boolean) {
    this.isFramesLoaded = value
  }

  setIsLottieAnimPrg(val: boolean) {
    this.isLottieAnim = val
  }

  setIsLottieAnim(val: boolean) {
    this.isLottieAnim = val
  }

  getCurrency(key: "m" | "t" = "t") {
    if (key == "t") {
      if (this.locale == LOCALES.RUSSIAN) {
        return "тыс."
      } else {
        return "thous."
      }
    } else {
      if (this.locale == LOCALES.RUSSIAN) {
        return "млн."
      } else {
        return "million"
      }
    }
  }

  setTransAll(trans: any[]) {
    this.transAll = trans;
  }

  setIsSettingsInited(val: boolean) {
    this.isSettingsInited = val;
  }

  setTechworks(val: any) {
    this.techworks = val;
  }

  setLocale(val: string) {
    this.locale = val;
  }

  setLocales(val: any[]) {
    this.locales = val;
  }

  setTranslations(val: any) {
    this.translations = val;
  }

  setFranchise(val: {key: string; value: string;}[]) {
    this.franchise = val;
  }

  setHomeSlides(val: any[]) {
    this.homeSlides = val
  }

  setPostCardWidth(val: string) {
    this.postCardWidth = val;
  }

  setSelectedPostId(val: number) {
    this.selectedPostId = val;
  }

  setUploadedImage2(val: string) {
    this.uploadedImage2 = val;
  }

  setUploadedImage(val: string) {
    this.uploadedImage = val;
  }
  
  setUploadedImages(val: string[]) {
    this.uploadedImages = val;
  }

  setSelectedItem(val: any) {
    this.selectedItem = val;
  }

  setSelectedRegItem(val: any) {
    this.selectedRegItem = val;
  }

  setSelectedBranchItem(val: any) {
    this.selectedBranchItem = val;
  }

  setOpennedItem(val: number) {
    this.opennedItem = val;
  }

  setSelectedCountry(val: number) {
    this.selectedCountry = val;
  }

  setSelectedRegion(val: number) {
    this.selectedRegion = val;
  }

  setSelectedBranch(val: number) {
    this.selectedBranch = val;
  }

  setGridShown(val: boolean) {
    this.gridShown = val;
  }

  setGridVisible(val: boolean) {
    this.gridVisible = val;
  }
  
  setPostsSlides(val: BlogPost[]) {
    this.postsSlides = val;
  }
  
  setPostsAll(val: BlogPost[]) {
    this.postsAll = val;
  }

  setPosts(val: BlogPost[]) {
    this.posts = val;
  }

  setBranchesAll(val: BranchModel[]) {
    this.branchesAll = val;
  }

  setBranches(val: BranchModel[]) {
    this.branches = val;
  }

  setBranchesStats(val: any) {
    this.branchesStats = val
  }

  setSelectedBranchServ(val: any) {
    this.selectedBranchServ = val
  }

  setSelectedBranchServSec(val: any) {
    this.selectedBranchServSec = val
  }

  setBranchesServs(val: any) {
    this.branchesServs = val
  }

  setAllBranches(val: BranchM[]) {
    this.allBranches = val;
  }

  setAllRegions(val: Region[]) {
    this.allRegions = val;
  }

  setAllOpenSum(val: string) {
    this.allOpenSum = val;
  }

  setCInit(val: boolean) {this.cInit = val;}
  setAnimationToggle(val: boolean) {this.animationToggle = val;}
  setAnimHiderLine(val: number) {this.animHiderLine = val;}
  setAnimSum1(val: string) {this.animSum1 = val;}
  setAnimSum2(val: string) {this.animSum2 = val;}
  setAnimSum3(val: string) {this.animSum3 = val;}
  setAnimSum4(val: string) {this.animSum4 = val;}
  setAnimSum5(val: string) {this.animSum5 = val;}
  setAnimSum6(val: string) {this.animSum6 = val;}
  setAnimSum7(val: string) {this.animSum7 = val;}

  setAnimSum8(val: string) {this.animSum8 = val;}
  setAnimSum9(val: string) {this.animSum9 = val;}
  setAnimSum10(val: string) {this.animSum10 = val;}
  setAnimSum11(val: string) {this.animSum11 = val;}
  
  setAnimT1(val: number) {this.animT1 = val;}
  setAnimT1Left(val: number) {this.animT1Left = val;}

  setAllOpenInter(val: any) {
    this.allOpenInter = val;
  }

  setC1Count(bl: string) {
    this.c1Count = bl;
  }
  setC2Count(bl: string) {
    this.c2Count = bl;
  }
  setC3Count(bl: string) {
    this.c3Count = bl;
  }
  setC4Count(bl: string) {
    this.c4Count = bl;
  }

  setRefuseDrop(bl: boolean) {
    this.refuseDrop = bl;
  }

  setSelectedSlide(bl: number) {
    this.selectedSlide = bl;
  }

  setIsHomeBlockVisible(bl: boolean) {
    this.isHomeBlockVisible = bl;
  }

  setMenuSelected(item: number) {
    this.menuSelected = item;
  }

  setIsHeaderLocked(bl: boolean) {
    this.isHeaderLocked = bl;
  }

  setIsBackCallVisible(bl: boolean) {
    this.isBackCallVisible = bl;
  }

  setIsContentShown(bl: boolean) {
    this.isContentShown = bl;
  }

  setIsMobile(bl: boolean) {
    this.isMobile = bl;
  }

  setIsMenuOpenned(bl: boolean) {
    this.isMenuOpenned = bl;
  }

  setIsFaderVisible(bl: boolean) {
    this.isFaderVisible = bl;
  }

  setIsHomeHeadCircVis(bl: boolean) {
    this.isHomeHeadCircVis = bl;
  }

  setIsFaderShown(bl: boolean) {
    this.isFaderShown = bl;
  }

  setIsFaderLogoVisible(bl: boolean) {
    this.isFaderLogoVisible = bl
  }

  logout() {
    localStorage.removeItem(StorageKeys.USER)
    localStorage.removeItem(StorageKeys.AUTHED)
    localStorage.removeItem(StorageKeys.TOKEN)

    this.isAuth = false
    this.userData = {is_barber: false}
    this.token = ""

    return true
  }

  setIsAuth(val: boolean) {
    this.isAuth = val
    localStorage.setItem(StorageKeys.AUTHED, val ? "1" : "0")
  }

  setSubscribeData(val: any) {
    this.subscribeData = val
  }

  setSubscribeBotData(val: any) {
    this.subscribeBotData = val
  }

  setUserDataVal(val: any) {
    this.userData = val
    localStorage.setItem(StorageKeys.USER, JSON.stringify(val))
  }

  setUserData(val: any, maxma: any = null) {
    
    this.userData = val
    this.subscribeData = val.active_sub
    this.subscribeBotData = val.active_bot_sub

    if (maxma != null) {
      this.maxmaUserData = maxma
    }

    localStorage.setItem(StorageKeys.USER, JSON.stringify(val))
    localStorage.setItem(StorageKeys.MAXMA_ACCOUNT, JSON.stringify(maxma))
  }

  applyMaxmaAuthWCode(data: any) {
    this.userData = data.user;
    this.token = data.token;
    // this.maxmaUserData = data.maxma;

    localStorage.setItem(StorageKeys.USER, JSON.stringify(data.user))
    // localStorage.setItem(StorageKeys.MAXMA_ACCOUNT, JSON.stringify(data.maxma))
    localStorage.setItem(StorageKeys.TOKEN, data.token)

    return true
  }

  applyMaxmaAuth(data: any) {
    this.userData = data.user;
    this.token = data.token;
    // this.maxmaUserData = data.maxma_account;

    localStorage.setItem(StorageKeys.USER, JSON.stringify(data.user))
    // localStorage.setItem(StorageKeys.MAXMA_ACCOUNT, JSON.stringify(data.maxma_account))
    localStorage.setItem(StorageKeys.TOKEN, data.token)
  }

  setupAuth(token: string, user: any) {
    this.isAuth = true;
    this.userData = user;
    this.token = token;

    localStorage.setItem(StorageKeys.USER, JSON.stringify(user));
    localStorage.setItem(StorageKeys.AUTHED, "1");
    localStorage.setItem(StorageKeys.TOKEN, token);
  }

  checkAuth() {
    const user = localStorage.getItem(StorageKeys.USER);
    const token = localStorage.getItem(StorageKeys.TOKEN);
    if (!isNullOrUndefined(user)) {
      let usr = JSON.parse(user)
      if (usr.role == "ADMIN") {
        this.isAuth = true;
        this.userData = JSON.parse(user)
        this.subscribeData = usr.active_sub
        this.subscribeBotData = usr.active_bot_sub
        this.token = token || ""
        return true
      } else {
        return false
      }
    }
    return false
  }

  checkAuthMaxma() {
    const user = localStorage.getItem(StorageKeys.USER)
    const token = localStorage.getItem(StorageKeys.TOKEN)
    const maxmaUser = localStorage.getItem(StorageKeys.MAXMA_ACCOUNT)
    if (!isNullOrUndefined(user)) {
      let usr = JSON.parse(user)
      if (usr.role == "USER") {
        this.userData = JSON.parse(user)
        // this.maxmaUserData = JSON.parse(maxmaUser)
        this.subscribeData = usr.active_sub
        this.subscribeBotData = usr.active_bot_sub
        this.token = token || ""
        
        // const authed = localStorage.getItem(StorageKeys.AUTHED)
        // if (authed == "1") {
        //   this.isAuth = true
        // }
        
        this.isAuth = true

        const ttlsl = localStorage.getItem(StorageKeys.TOTAL_SALE)
        if (ttlsl != null) {
          this.totalSale = parseInt(ttlsl)
        }

        return true
      } else {
        return false
      }
    }
    
    return false
  }

}
